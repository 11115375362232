import React from 'react';
import brand from '../../images/contact-brand.png';
import './contact.css';
import Form from './form'

const contact = () => {
    
    return (
        <section className='contact-bg'>
            <div className="contact-section wrapper">  
                 
                <div className="contact-left">
                    <div className="contact-heading"> 
                        <img src={brand} alt="Molto Digital Limited - Website, Graphic, Branding" />
                    </div>
                    <div className="contact-address">
                        <p>
                        Rm 2311, 23/F, Millennium City 5,<br/>
                        No. 418 Kwun Tong Road,<br/>
                        Kwun Tong, Kowloon
                        Hong Kong,<br/>

                        </p>
                        <p>(+852) 5482 4780</p>
                    </div>

                    <div className="contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1845.547409247805!2d114.22511829999999!3d22.3122535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404014f357aa82f%3A0xae7cb558a3df04e7!2sMillennium%20City%20Phase%20V!5e0!3m2!1sen!2shk!4v1653018912824!5m2!1sen!2shk" width="100%" height="550" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <div className="contact-right">
                    <div className="contact-form">
                        <div className="contact-form-heading">  
                            enquire
                        </div>
                        <Form />
                    </div>
                    <div className="contact-enquiry">
                        We are always interested to hear from enthusiastic and talented designers and programmers. Send your portfolio to <span>hello@moltodigital.com</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default contact
