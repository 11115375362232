import React from 'react';
import footerLogo from '../../images/molto-logo-footer@2x.png';
import './footer.css';
import { Link } from 'react-router-dom';

function scrollRotate() {
    let image = document.getElementById("watermelon");
    image.style.transform = "rotate(" + window.pageYOffset/2 + "deg)";
}

const footer = (props) => {
    window.onscroll = function () {
        scrollRotate();
    };

    const today = new Date();

    return (
        <>
        <Link className="watermelon" id="watermelon" to="/contact">
            <span></span>
        </Link>
       
        <section className={`footer ${props.page}`}>
            <div className="footer-wrapper wrapper">
                <div className="footer-cols">
                    <div className="footer-col footer-col-1">
                        <a className="footer-logo" href="index.php"><img src={footerLogo} alt="Molto Digital Limited" /></a>
                    </div>
                    <div className="footer-col footer-col-2">
                        <div className="footer-menu">
                            <ul>
                                <li><Link to="/">about</Link></li>
                                <li><Link to="/contact">contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer-col footer-col-3">
                        <div className="footer-address">
                            <p>Rm 2311, 23/F, Millennium City 5,<br/>
                                No. 418 Kwun Tong Road,<br/>
                                Kwun Tong, Kowloon, Hong Kong</p>
                        </div>
                    </div>
                    <div className="footer-col footer-col-4">
                        <div className="footer-contact">
                            <p>contact@moltodigital.com<br />+852 5482 4780</p>
                        </div>
                        <div className="copyright">&copy; {today.getFullYear()} Molto Digital Limited</div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

export default footer
